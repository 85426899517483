import research from "./media/research.jpg"

const accordionItems = [
    {
      image: research,
      title: 'Undergraduate Research Grant',
      description: `My team secured a competitive undergraduate research grant from the American University of Sharjah in 
      January 2023. We developed an IoT-enhanced customer analytics system for SME retailers, aiming to 
      improve their understanding of customer behavior. This project showcased our ability to conduct meaningful research 
      and deliver practical solutions.`,
    },
    {
      title: 'University Peer Leader',
      description: `As a Peer Leader, I assisted incoming freshmen in their transition to university life at the American 
      University of Sharjah. Providing mentorship and support, I helped them navigate academic and social challenges, 
      fostering a positive campus experience.`,
    },
    {
      title: 'Injaz Annual Competition',
      description: `I contributed to the development and enhancement of a company's website as part of the Injaz Company Project. 
      Our efforts led to winning the Product of the Year award, highlighting our ability to deliver impactful digital solutions 
      aligned with business objectives.`,
    },

    {
      title: 'NGN 110 (Engineering students’ competition)',
      description: `My team won first place in the NGN 110 engineering competition for designing a lightweight dome structure 
      capable of sustaining a specified weight. This experience highlighted our innovation and teamwork skills in addressing 
      engineering challenges.`,
    },

    
  ];

  export default accordionItems;
  