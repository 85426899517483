import React from "react";
import './Intro.css';

export default function Intro() {
    const aboutRef = React.useRef(null);

    return (
        <div ref={aboutRef} id="intro" className="intro-container">
            <div className="right-section">
                <p> I'M <span className="my-name">Syed Faizan</span> <br/>Computer Engineering Graduate</p>     
            </div>
            <div className="left-section">
                <div className="image-container">
                    <img src={require('./mypic4.png')} alt="mypic" />
                </div>
            </div>
        </div>
    );
}