import React from "react";
import Intro from "./home/Intro.js";
import Skills from "./home/Skills.js";
import projectsData from "./home/projectsData.js";
import associationData from "./home/associationData.js";
import Navigation from "./home/Navigation"; 
import "./App.css";
import ProjectGrid from "./home/ProjectGrid.js";
import Accordion from "./home/Accordion.js";

const App = () => {
  return (
    <div>
      <Navigation  /> 
      <div className="home--page">
        <div id="about"><Intro /></div>

        <div id="skills"><Skills /></div>
        
        <ProjectGrid 
          title="Relevant Projects"
          id="projects"
          projectData={projectsData}
        />

        <Accordion 
          items={associationData} 
          title="Associations"
          id="associations"
        />

      </div>
    </div>
  );
};

export default App;
