import React from 'react';
import "./SkillBox.css";
import { Element } from 'react-scroll';

const SkillBox = ({ skill }) => {
    return (
        <Element name={skill.text}>
            <div className="skill-box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" >
                <img src={skill.image} alt={skill.text} />
                <span>{skill.text}</span>
            </div>
        </Element>
    );
};

export default SkillBox;
