import React, { useState } from 'react';
import './Accordion.css'; // Import your CSS file for styling

const Accordion = (props) => {
  const [activeIndex, setActiveIndex] = useState(-1); // -1 to indicate no initially open item

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index); // Toggle active state
  };
  const aboutRef = React.useRef(null);

  return (
    <div ref={aboutRef} id="associations" className="accordion"> 
        <h1>{props.title}</h1>
      {props.items.map((item, index) => (
        <div key={index} className={`accordion-item ${activeIndex === index ? 'active' : ''}`} onClick={() => handleClick(index)} >
          <div className="accordion-heading " data-aos="slide-right" data-aos-duration="1000" data-aos-delay="100">
            <h3 className="accordion-title">
             <div className='bullet'>●</div>{item.title}
            </h3>
            <div className="sign">{activeIndex === index ? '━' : '+'}</div> {/* Display minus sign if active, otherwise plus sign */}
            <div className="accordion-description-container">
                
              <p className={`project-description ${activeIndex !== index ? 'short' : ''}`}>
                {item.description} 

              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
