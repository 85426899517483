import React from 'react';
import "./Skills.css"
import ads from "./media/ads.jpg"; 
import multisim from "./media/multisim.jpg"; 
import javaScript from "./media/javaScript.png"; 
import java from "./media/java.webp"; 
import react from "./media/react.png"; 
import python from "./media/python.jpg"; 
import cplusplus from "./media/cplusplus.png"; 
import csharp from "./media/csharp.png"; 
import matlab from "./media/matlab.png"; 
import verilog from "./media/verilog.jpg"; 
import cisco from "./media/cisco.png"; 
import html from "./media/html.jpg"; 
import css from "./media/css.jpg"; 
import flutter from "./media/flutter.jpg"

import SkillBox from "./SkillBox";

const Skills = () => {
  const skills = [
      { text: "Advanced Design System", image: ads },
      { text: "NI Multisim", image: multisim },
      { text: "JavaScript", image: javaScript },
      { text: "Java", image: java },
      { text: "Python", image: python },
      { text: "C++", image: cplusplus },
      { text: "C#", image: csharp },
      { text: "MATLAB", image: matlab },
      { text: "Verilog", image: verilog },
      { text: "Cisco Packet Tracer", image: cisco },
      { text: "HTML", image: html },
      { text: "CSS", image: css },
      { text: "Flutter", image: flutter },
      { text: "React", image: react },
  ];

  return (
      <div className="skills-section" >
          <div className="about-me">
          <h2><div className='line'>━</div>About </h2>
                <p>I'm a recent Computer Engineering graduate from the American University of Sharjah. Specializing in software and web development, 
                  I've excelled in JavaScript, Java, Python, and C++. Proficient in automation software and web development using Node.js, I also have hands-on 
                  experience with Raspberry Pi and Arduino for hardware projects. Throughout my academic journey, I've actively engaged in projects, 
                  secured research grants, and competed in engineering contests, showcasing adaptability and teamwork. Beyond studies, 
                  I'm passionate about environmental sustainability, evident in my involvement with sustainable farming at Injaz Company Project.




                </p>
                
          </div>
          <div className="skill-container">
              <h3 className="skills">Skills</h3>
              <div className="skill-grid">
                  {skills.map((skill, index) => (
                      <SkillBox key={index} skill={skill} />
                  ))}
              </div>
          </div>
      </div>
  );
};

export default Skills;
