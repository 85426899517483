import React from 'react';
import './ProjectGrid.css';

const ProjectGrid = (props) => {
  const { projectData } = props;
  const aboutRef = React.useRef(null);
  const handleDownload = (fileUrl) => {
    window.open(fileUrl, '_blank'); // Open the file URL in a new tab
  };
  return (
    
    <div ref={aboutRef} id="projects" className='project-grid-container'>
      <h3>{props.title}</h3>
    <div className="project-grid">
         
      {projectData.map((project, index) => (
        <div key={index} className="project-card " data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">
          <img src={project.image} alt={project.title} />

          <div className="project-details">
            <h3>{project.title}</h3>
            <p>{project.content}</p>
            <button onClick={() => handleDownload(project.fileUrl)}>Download Report</button>          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default ProjectGrid;
