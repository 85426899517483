import machine from "./media/machine.jpg";
import deep from "./media/deep.jpg"
import game from "./media/game.jpg"
import smart from "./media/smart.jpg"
import vr from "./media/vr.png"
import airline from "./media/airline.pdf"
import sme from "./media/sme.pdf"
import smartpdf from "./media/smart.pdf"
import vrpdf from "./media/vr.pdf"
import gamepdf from "./media/game.pdf"

const projectsData = [
  {
    fileUrl: airline,

    image: machine,
    title: 'Airline Satisfaction Prediction',
    content: 
      <>
        <p>
          Developed machine learning model to predict passenger satisfaction in airlines using machine learning techniques 
          like feature selection, dimensionality reduction, and model evaluation. 
        </p>
      </>
  },
  {
    fileUrl: sme,

    image: deep,

    title: 'Analytics Solution for SME Retailers',
    content: 
      <>
        <p>
          Developed customer analytics solution integrating long range RFID technology and deep learning algorithms to empower SME 
          retailers with actionable insights into customer behavior.
        </p>
      </>
  },
  {
    fileUrl: smartpdf,

    image: smart,

    title: 'Smart Home Automation Model',
    content: 
      <>
        <p>
          Smart home automation system integrating hardware components and software functionalities for 
          motion detection, Flask server integration, and ThingSpeak logging.
        </p>
      </>
  },
  {
    image: vr,
    fileUrl: vrpdf,

    title: 'VR Pac-Man',
    content: 
      <>
        <p>
          Developed the classic Pac-Man arcade game into virtual reality, offering players an immersive gaming experience. 
          With VR headsets and AI-controlled ghosts.
        </p>
      </>
  },
  {
    image: game,
    fileUrl: gamepdf,

    title: 'Text-Based Adventure Game JAVA',
    content: 
      <>
        <p>
        Developed a text-based adventure game where players navigate through various levels, battling enemies. 
        utlizing design patterns and mobile sensors.
        </p>
      </>
  }
];

export default projectsData;
